


















































































































































































































































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import FormCheckbox from '@/components/form/form-checkbox.vue';
import operatingModes from '@/config/operating-modes';
import errorMessage from '@/components/form/error-message.vue';

import 'vue-select/dist/vue-select.css';
import { CASE_STATUS } from '@/constant/constants';
import popUpModal from '@/components/form/pop-up-modal.vue';

interface PriceItem {
  Id: string;
  NatürlichePerson: boolean;
  DomizilSchutz: boolean;
  Geltungsbereich: string;
  Laufzeit: number;
  AnzahlPersonen: number;
  VersicherungssummeJePerson: number;
}
const personItem = {
  subject: '',
  price: 0,
  country: '',
  coverageAtHome: false,
  isSelected: false,
};
const groupItem = {
  subject: '',
  price: 0,
  country: '',
  coverageAtHome: false,
  isSelected: false,
  numberOfParticipants: 1,
};
const personType = 1;
const groupType = 2;
export default Vue.extend({
  name: 'LuggageInsurance',
  components: {
    FormCheckbox,
    FormSwitch,
    errorMessage,
    popUpModal,
  },
  props: ['assesment', 'insuranceKey', 'caseStatus', 'insuranceData', 'isAPIObserver', 'AMSAPISendStatus'],
  data() {
    return {
      type: 'main',
      operatingModes,
      operatingMode: '',
      remarks: '',
      nextPath: '',
      processName: '',
      haveBussiness: false,
      haveTransport: false,
      byPerson: false,
      byGroup: false,
      showCountryRelationsError: true,
      byPersonType: personType,
      byGroupType: groupType,
      personItems: [
        {
          ...personItem,
        },
      ],
      groupItems: [
        {
          ...groupItem,
        },
      ],
      key: this.insuranceKey || '',
      componentLoaded: false,
      mainPriceError: false,
      mainSubjectError: false,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
    };
  },
  watch: {
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
    personItems: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
    groupItems: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    dublicateRow(fillData: boolean, type: number) {
      let item;
      switch (type) {
        case this.byPersonType:
          item = JSON.parse(JSON.stringify(personItem));
          if (fillData && this.personItems.length > 0) {
            item = JSON.parse(JSON.stringify(this.personItems[this.personItems.length - 1]));
          }
          this.personItems.push({
            ...item,
          });
          break;
        case this.byGroupType:
          item = JSON.parse(JSON.stringify(groupItem));
          if (fillData && this.groupItems.length > 0) {
            item = JSON.parse(JSON.stringify(this.groupItems[this.groupItems.length - 1]));
          }
          this.groupItems.push({
            ...item,
          });
          break;
        default:
          console.log('Wrong type');
      }
    },
    deleteRows(type: number) {
      let items;
      switch (type) {
        case this.byPersonType:
          items = this.personItems.filter((item) => !item.isSelected);
          this.personItems = items;
          break;
        case this.byGroupType:
          items = this.groupItems.filter((item) => !item.isSelected);
          this.groupItems = items;
          break;
        default:
          console.log('Wrong type');
      }
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    setValues(formData: any) {
      if (formData.assesment.processName) {
        this.processName = formData.assesment.processName;
      } else if (formData.insuranceData.processName) {
        this.processName = formData.insuranceData.processName;
      }
      if (formData.insuranceData.personItems) {
        this.personItems = formData.insuranceData.personItems;
      }
      if (formData.insuranceData.groupItems) {
        this.groupItems = formData.insuranceData.groupItems;
      }
      if (formData.insuranceData.byPerson) {
        this.byPerson = formData.insuranceData.byPerson;
      }
      if (formData.insuranceData.byGroup) {
        this.byGroup = formData.insuranceData.byGroup;
      }
      if (formData.insuranceData.haveBussiness) {
        this.haveBussiness = formData.insuranceData.haveBussiness;
      }
      if (formData.insuranceData.haveTransport) {
        this.haveTransport = formData.insuranceData.haveTransport;
      }
      if (formData.insuranceData.remarks) {
        this.remarks = formData.insuranceData.remarks;
      }
    },
    onNextClick() {
      const priceData: PriceItem[] = [];

      if (this.byPerson) {
        this.personItems.forEach((item) => {
          if (item.price > 0) {
            this.mainPriceError = false;
            priceData.push({
              Id: item.subject,
              NatürlichePerson: true,
              DomizilSchutz: item.coverageAtHome,
              Geltungsbereich: item.country,
              Laufzeit: 1,
              AnzahlPersonen: 1,
              VersicherungssummeJePerson: item.price,
            });
          } else {
            this.mainPriceError = true;
          }
          if (item.subject === '') {
            this.mainSubjectError = true;
          } else {
            this.mainSubjectError = false;
          }
        });
      }

      if (this.byGroup) {
        this.groupItems.forEach((item) => {
          if (item.price > 0) {
            priceData.push({
              Id: item.subject,
              NatürlichePerson: false,
              DomizilSchutz: item.coverageAtHome,
              Geltungsbereich: item.country,
              Laufzeit: 1,
              AnzahlPersonen: item.numberOfParticipants,
              VersicherungssummeJePerson: item.price,
            });
          } else {
            this.mainPriceError = true;
          }
        });
      }
      if (this.mainPriceError) {
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
        return;
      }
      const data = {
        processName: this.processName,
        personItems: this.personItems,
        groupItems: this.groupItems,
        byPerson: this.byPerson,
        byGroup: this.byGroup,
        totalPrice: this.totalPrice,
        haveBussiness: this.haveBussiness,
        haveTransport: this.haveTransport,
        remarks: this.remarks,
      };
      const pData = {
        Zahlweise: 'Jährlich',
        Risiken: priceData,
      };
      this.$emit('risk-assesment-change', {
        remarks: this.remarks,
        haveTransport: this.haveTransport,
        haveBussiness: this.haveBussiness,
      });
      this.$emit(
        'data-changed',
        '05869e8d-eca7-4aa6-8395-ff2dce04bc3e',
        pData,
        data,
        'LUGGAGE_INSURANCE',
      );
    },
    scrollToFirstErrorElement() {
      const el = this.$el.getElementsByClassName('red-border')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
  },
  computed: {
    changeData(): any {
      return [this.haveBussiness, this.haveTransport, this.remarks, this.processName, this.personItems, this.groupItems, this.byPerson, this.byGroup, this.totalPrice].join();
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
    totalPrice(): any {
      let byPersonPrice = 0;
      if (this.byPerson === true) {
        byPersonPrice = this.personItems.reduce((acc, item) => acc + item.price, 0);
      }
      let byGroupPrice = 0;
      if (this.byGroup === true) {
        byGroupPrice = this.groupItems.reduce((acc, item) => (item.numberOfParticipants > 0 ? acc + (item.price * item.numberOfParticipants) : acc + item.price), 0);
      }
      return byGroupPrice + byPersonPrice;
    },
  },
  beforeUpdate() {
    this.componentLoaded = true;
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (this.$attrs.formData) {
      this.setValues(this.$attrs.formData);
    } else if (this.insuranceData) {
      const formData = {
        insuranceData: this.insuranceData,
      };
      this.setValues(formData);
    }
    this.$emit(
      'risk-assesment-change',
      {
        operatingMode: this.operatingMode,
      },
      'LUGGAGE_INSURANCE',
    );
  },
});
