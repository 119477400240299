
























































































































































































































































































































































































































































import Vue from 'vue';
import vSelect from 'vue-select';
import FormSwitch from '@/components/form/form-switch.vue';
import operatingModes from '@/config/operating-modes';
import errorMessage from '@/components/form/error-message.vue';
import popUpModal from '@/components/form/pop-up-modal.vue';
import 'vue-select/dist/vue-select.css';
import CountriesList from '@/components/form/countries-list.vue';
import COUNTRIES from '@/config/countires-list';
import IRiskAssesment from '@/model/IRiskAssesment';
import { CASE_STATUS } from '@/constant/constants';
import priceCalculator from '@/service/price-calculator';

type OperatingMode = {
  [key: string]: string;
};

export default Vue.extend({
  name: 'asset-insurance',
  components: {
    FormSwitch,
    vSelect,
    errorMessage,
    popUpModal,
    'countries-list': CountriesList,
  },
  data() {
    return {
      operatingModes,
      operatingMode: '',
      processName: '',
      remarks: '',
      isSelectOpen: false,
      key: this.insuranceKey || 'VALOR_INSURANCE_COMPACT',
      showCountryRelationsError: true,
      nextPath: '',
      isUpdated: false,
      componentLoaded: false,
      haveBussiness: false,
      haveTransport: false,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      aShop: true,
      pureManufacturing: false,
      alarmSystem: true,
      name: '',
      street: '',
      postCode: '',
      place: '',
      country: 'Deutschland',
      totalSales: 0,
      riskAddress: 'DE',
      safe: false,
      BetriebsartValoren: '',
      safeList: [
        {
          key: 'LessThanBmax',
          name: 'Geringer als „B“ max. VSu = 0 EUR',
          price: 0,
          val: 'A',
        },
        {
          key: 'ThanBmax',
          name: '„B“ max. VSu = 100.000 EUR',
          price: 100000,
          val: 'B',
        },
        {
          key: 'EURO0max',
          name: 'EURO 0 max. VSu = 120.000 EUR',
          price: 120000,
          val: 'C',
        },
        {
          key: 'EUROImax',
          name: 'EURO I max. VSu = 200.000 EUR',
          price: 200000,
          val: 'D',
        },
        {
          key: 'BetterThanEUROImax',
          name: 'Besser als EURO I max. VSu = 200.000 EU',
          price: 200000,
          val: 'E',
        },
      ],
      safe1: 'A',
      safe2: 'A',
      sefeOverhungry: '',
    };
  },
  props: ['assesment', 'insuranceKey', 'caseStatus', 'insuranceData', 'isAPIObserver', 'AMSAPISendStatus'],
  watch: {
    operatingMode(value: OperatingMode): OperatingMode {
      this.BetriebsartValoren = value.BART_TEXT;
      return value;
    },
    assesment: {
      deep: true,
      handler(value: IRiskAssesment) {
        if (!this.isUpdated) {
          this.setValuesOperations();
          this.isUpdated = true;
          // this.key = this.insuranceKey;
        }
        return value;
      },
    },
    insuranceKey(key) {
      this.key = key;
    },
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    selectRiskAddress() {
      const index = COUNTRIES.findIndex((country) => this.country === country.Land);
      this.riskAddress = COUNTRIES[index]['ISO-Code'];
    },
    getCityByZipCode(event: any) {
      priceCalculator.checkZipCode(this.postCode).then((response) => {
        this.place = response.data.postalCode.city;
      });
    },
    onDropDownClick() {
      this.isSelectOpen = !this.isSelectOpen;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.operatingModeRef as any).open = this.isSelectOpen;
    },
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    setValues(formData: any) {
      // this.assesment.operatingMode.BART_TEXT = formData.assesment.operatingMode.BART_TEXT;
      // this.operatingMode = (this.assesment as IRiskAssesment).operatingMode || '';
      if (formData.insuranceData.processName) {
        this.processName = formData.insuranceData.processName;
      } else if (typeof formData.assesment !== 'undefined') {
        this.processName = formData.assesment.processName;
      }
      if (formData.assesment.operatingMode) {
        this.operatingMode = formData.assesment.operatingMode;
      }
      if (formData.insuranceData.postCode) {
        this.postCode = formData.insuranceData.postCode;
      }
      if (formData.insuranceData.place) {
        this.place = formData.insuranceData.place;
      }
      if (formData.insuranceData.country) {
        this.country = formData.insuranceData.country;
      }
      if (formData.insuranceData.totalSales) {
        this.totalSales = formData.insuranceData.totalSales;
      }
      if (formData.insuranceData.safe) {
        this.safe = formData.insuranceData.safe;
      }
      if (formData.insuranceData.safe1) {
        this.safe1 = formData.insuranceData.safe1;
      }
      if (formData.insuranceData.safe2) {
        this.safe2 = formData.insuranceData.safe2;
      }
      if (formData.insuranceData.aShop) {
        this.aShop = formData.insuranceData.aShop;
      }
      if (formData.insuranceData.pureManufacturing) {
        this.pureManufacturing = formData.insuranceData.pureManufacturing;
      }
      if (formData.insuranceData.alarmSystem) {
        this.alarmSystem = formData.insuranceData.alarmSystem;
      }
      if (formData.insuranceData.name) {
        this.name = formData.insuranceData.name;
      }
      if (formData.insuranceData.street) {
        this.street = formData.insuranceData.street;
      }
      if (formData.insuranceData.postCode) {
        this.postCode = formData.insuranceData.postCode;
      }
      if (formData.insuranceData.remarks) {
        this.remarks = formData.insuranceData.remarks;
      }
    },
    setAssesment(assesment: any) {
      this.operatingMode = (assesment as IRiskAssesment).operatingMode || '';
      this.remarks = (assesment as IRiskAssesment).remarks || '';
      this.processName = (assesment as IRiskAssesment).processName || '';
      this.haveTransport = (assesment as IRiskAssesment).haveTransport || false;
      this.haveBussiness = (assesment as IRiskAssesment).haveBussiness || false;
    },
    setValuesOperations() {
      this.operatingMode = (this.assesment as IRiskAssesment).operatingMode || '';
    },
    onChange(path: string, key: string) {
      this.nextPath = path;
      this.key = key;
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    getApiData() {
      priceCalculator.getUserAPIData().then((response) => {
        if (response.data !== null) {
          if (this.processName === '') {
            this.processName = response.data.assesment.processName;
          }
        }
      });
    },
    onSubmit() {
      this.$emit(
        'risk-assesment-change',
        {
          operatingMode: this.operatingMode,
          remarks: this.remarks,
          processName: this.processName,
          haveTransport: this.haveTransport,
          haveBussiness: this.haveBussiness,
        },
        this.key,
      );
      const data = {
        remarks: this.remarks,
        processName: this.processName,
        haveTransport: this.haveTransport,
        haveBussiness: this.haveBussiness,
        aShop: this.aShop,
        pureManufacturing: this.pureManufacturing,
        alarmSystem: this.alarmSystem,
        name: this.name,
        street: this.street,
        postCode: this.postCode,
        place: this.place,
        country: this.country,
        totalSales: this.totalSales,
        safe: this.safe,
        safe1: this.safe1,
        safe2: this.safe2,
      };
      const priceData = {
        BetriebsartValoren: this.BetriebsartValoren,
        Herstellungsbetrieb: this.pureManufacturing,
        Risikoanschrift: this.riskAddress,
        Versicherungssumme: this.totalSales,
        Ladengeschäft: this.aShop,
        MeldeAnlage: this.alarmSystem,
        Geltungsbereich: this.country,
      };
      this.$emit('data-changed', '20837dc0-8918-4d4c-8744-c4998522beed', priceData, data);
    },
  },
  computed: {
    operatingModesValoren(): any {
      return this.operatingModes.filter((item) => item['BART-Gruppe'] === 'VAL');
    },
    changeData(): any {
      return [this.operatingMode].join();
    },
    isBARTTypeVAL(): boolean {
      return (this.operatingMode as any)['BART-Gruppe'] === 'VAL';
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
    priceMoreHundredThousand(): boolean {
      if (this.totalSales >= 100000) {
        return true;
      }
      return false;
    },
    priceLessHundredThousand(): boolean {
      if (this.totalSales < 100000) {
        return true;
      }
      return false;
    },
    priceLessTwoHundredThousand(): boolean {
      if (this.totalSales > 200000) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getApiData();
    if (!this.assesment) {
      this.$router.replace('/transport-insurance');
    } else {
      this.setAssesment(this.assesment);
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (this.$attrs.formData) {
      this.setValues(this.$attrs.formData);
    } else if (this.insuranceData) {
      const formData = {
        insuranceData: this.insuranceData,
        assesment: this.assesment,
      };
      this.setValues(formData);
    }
    this.$emit(
      'on-key-change',
      this.key,
    );
    // this.setValues();
    // if (this.isDisableAll) {
    //   this.disableAllInputs();
    //   // this.onNextClick();
    // }
  },
});
